import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import StatusPage from '../ui/pages/StatusPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'

const Status = props => (
  <>
    <HelmetComponent
      title={metaTags.indexTitle}
      description={metaTags.index}
      page="de/status"
    />
    <AppWrapper {...props} lang="de">
      <StatusPage />
    </AppWrapper>
  </>
)

export default Status
